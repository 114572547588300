import React from 'react';
import { Typography, Grid2 } from '@mui/material';
import PlanBox from './plan-box';
import swimmer from './../../images/swimmer-icon.svg'
import runner from './../../images/runner-icon.svg'
import cyclist from './../../images/biker-icon.svg'
import glass from './../../images/search-icon.svg'

export default function Plans() {
    return (
        <Grid2 container spacing={2} width={"100%"} style={{marginTop:"3%"}}>
            <Grid2 
                size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                }}
            >
                <PlanBox
                    header="Triathlon Coaching" 
                    content="
                        Embark on a personalized journey to triathlon success with our 
                        Triathlon Coaching plan. This comprehensive program is tailored 
                        to your unique needs, helping you master swimming, cycling, and 
                        running techniques for a well-rounded performance.
                    "
                    defaultMessage="
                        Hello,

                        I am interested in the Triathlon Coaching option that you offer. 
                        I am looking for a comprehensive approach to mastering swimming,
                        cycling, and running plan that is tailored to my needs.

                        Could you please provide me with more information on how to get 
                        started with this plan? I would greatly appreciate it.
                    "
                >
                    <Grid2 container spacing={1}>
                        <Grid2 size={12}>
                            <Typography>
                                Triathlon Coaching
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Grid2 container>
                                <Grid2 size={4}>
                                    <img src={swimmer} class="filter-white" style={{width:"33%", height:"100%"}}
                                        alt="athlete"
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <img src={cyclist} class="filter-white" style={{width:"33%"}}
                                        alt="athlete"
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <img src={runner} class="filter-white" style={{width:"33%"}}
                                        alt="athlete"
                                    />  
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2> 
                </PlanBox>
            </Grid2>
            <Grid2 
                size={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                }}
            >
                <PlanBox 
                    header="Run Coaching" 
                    content="
                        Elevate your running performance with our Run Coaching plan. 
                        Our expert coach will provide personalized guidance, helping 
                        you improve your speed, endurance, and technique, whether 
                        you're a beginner or a seasoned runner.
                    "
                    defaultMessage="
                        Hello,

                        I am interested in the Running Coaching option that you offer. 
                        I am looking for a comprehensive approach to becoming more adept
                        at running and improving my times.

                        Could you please provide me with more information on how to get 
                        started with this plan? I would greatly appreciate it.
                    "
                >
                    <Grid2 container spacing={1} style={{width:"100%"}}>
                        <Grid2 size={12}>
                            <Typography>
                                Run Coaching
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Grid2 container>
                                <Grid2 size={4} offset={4}>
                                    <img src={runner} class="filter-white" style={{width:"33%"}}
                                        alt="athlete"
                                    />  
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2> 
                </PlanBox>
            </Grid2>
            <Grid2 
                size={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                }}
            >
                <PlanBox 
                    header="Cycling Coaching"
                    content="
                        Gear up for cycling success with our Cycling Coaching 
                        plan. Our coach will help you optimize your cycling technique, 
                        build endurance, and enhance your performance, whether you're 
                        training for a race or riding for leisure.
                    "
                    defaultMessage="
                        Hello,

                        I am interested in the Cycling Coaching option that you offer. 
                        I am looking for a comprehensive approach to becoming more adept
                        at running and improving my times.

                        Could you please provide me with more information on how to get 
                        started with this plan? I would greatly appreciate it.
                    "
                >
                    <Grid2 container spacing={1}>
                        <Grid2 size={12}>
                            <Typography>
                                Cycling Coaching
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Grid2 container>
                                <Grid2 size={4} offset={4}>
                                    <img src={cyclist} class="filter-white" style={{width:"33%"}}
                                        alt="athlete"
                                    />  
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2> 
                </PlanBox>
            </Grid2>
            <Grid2 
                size={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                }}
            >
                <PlanBox 
                    header="Biomechanincs Analysis"
                    content="
                        For the run biomechanical analysis athletes will meet one on one 
                        with Nick. Typically this will take place at a track, running 
                        path, or home treadmill. During the course of the session Nick 
                        will give feedback and analyze running mechanics visually and 
                        through film. Various topics will be discussed during the course 
                        of the session that will better allow the athlete to understand 
                        their mechanics and what can be done to help improve them if 
                        needed. Following the completion of the meeting athletes will be 
                        provided a full write up of the biomechanical analysis within two 
                        business days.
                    "
                    defaultMessage="
                        Hello,

                        I am interested in you Running Biomechanics option that you offer. 
                        I would like to improve my running mechanics to help reduce injury
                        and increase my ability to run in the long term.

                        Could you please provide me with more information on how to get 
                        started with this plan? I would greatly appreciate it.
                    "
                >
                    <Grid2 container spacing={1}>
                        <Grid2 size={12}>
                            <Typography>
                                Biomechanincs Analysis
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Grid2 container>
                                <Grid2 size={4} offset={4}>
                                    <img src={glass} class="filter-white" style={{width:"33%"}}
                                        alt="athlete"
                                    />  
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2> 
                </PlanBox>
            </Grid2>
        </Grid2>
    );
};
