import React from 'react';
import {  
    Typography, 
    Grid2,
    CardMedia,
    Divider
} from '@mui/material';
import mattMason from './../../images/MattMason.png'
import mattGoedeke from './../../images/MattGoedeke.jpg'
import shylahAndrews from './../../images/ShylahAndrews.jpg'


export default function Testimonials() {
    return (
        <Grid2 container spacing={4} style={{marginBottom:"15%"}}>
            <Grid2 item>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{xs:12, md:12, lg:4 }}>
                        <CardMedia
                            component="img"
                            src={mattMason}
                            alt="Matt being a badass"
                            style={{ width: "100%", height: "100%" }}
                        />
                    </Grid2>
                    <Grid2 item size={{xs:12, md:12, lg:8}}>
                        <Typography variant="subtitle1" fontStyle="italic">
                        Nick is an excellent coach and I would highly recommend him to anyone. 
                        He provides highly professional coaching and delivers it with a personal
                         touch. Nick has coached me for the past two years and it has been a 
                         great experience. He reviewed my sport history and we had a 
                         comprehensive initial consultation; after speaking with him I knew he 
                         was the right choice. Nick helped guide me through multiple marathons, 
                         70.3s and a full ironman. Nick leads by example with his training. 
                         He is not only book smart with his degree in exercise physiology but 
                         he walks the walk with a discipline training schedule that is admirable 
                         to anyone. Being directly involved with endurance sport he is able to 
                         directly relate to his clients. Nick recently guided me to a sub 10 
                         hour ironman for my first full and I certainly would not have been 
                         able to do it without him. I don’t think the training could have been 
                         better with the right mix of volume and intensity. I look forward to a 
                         long coach client relationship with Nick as he is the best in the 
                         business.
                        </Typography>
                        <Typography>- Matt Mason</Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 item size={12}>
                <Divider/>
            </Grid2>
            <Grid2 item size={12}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{xs:12, md:12, lg:4 }}>
                        <CardMedia
                            component="img"
                            src={mattGoedeke}
                            alt="Matt being a badass"
                            style={{ width: "100%", height: "100%" }}
                        />
                    </Grid2>
                    <Grid2 item size={{xs:12, md:12, lg:8}}>
                        <Typography variant="subtitle1" fontStyle="italic">
                        Nick did an absolutely incredible job preparing me for my first ever 
                        marathon. As a 42 year old dad with 3 kids and full-time job he 
                        developed a program that was perfect for me and accommodating to my 
                        lifestyle. Any question I had about a workout was answered with real 
                        data that was proven to be true over and over again. He was also 
                        incredible with adjusting workouts on the fly. If I had something 
                        come up unexpectedly for work or family he was always available 
                        in a moments notice to help me adjust the workout. My plan was to 
                        be a one and done marathoner but bc of Nick’s guidance I can’t see 
                        just stopping now. 
                        </Typography>
                        <Typography>- Matt Goedeke</Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 item size={12}>
                <Divider/>
            </Grid2>
            <Grid2 item size={12}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{xs:12, mg:12, lg:4 }}>
                        <CardMedia
                            component="img"
                            src={shylahAndrews}
                            alt="Shylah being a badass"
                            style={{ width: "100%", height: "100%" }}
                        />
                    </Grid2>
                    <Grid2 item size={{xs:12, md:12, lg:8}}>
                        <Typography variant="subtitle1" fontStyle="italic">
                        I started working with Nick in 2021 when I decided to race Musselman 
                        70.3 after taking 6 years away from triathlon to focus on racing bikes. 
                        I set an ambitious goal of hitting 4:45 with only two months to prepare. 
                        Nick set me up to hit that goal, resulting in a 5th place overall finish 
                        and qualification for 70.3 World Championships. Since then, Nick has 
                        continued to challenge me with increasingly difficult workouts, things 
                        that on paper I question whether I'm capable of, but surprisingly I am 
                        able to nail because of the compounded effects of his training over the 
                        last few years. I've stayed at the front of the field in races and my 
                        overall fitness is the best it has ever been. My swim speed and 
                        confidence has improved by about 10 seconds/100 yards and my run has 
                        been strong without losing any speed on the bike. Nick also knows it's 
                        important to love what you do and continue to have fun, so is flexible 
                        with long rides, group rides or throwing in some smaller local races 
                        with no pressure. He also knows the importance of recovery and taking 
                        the easy days easy, and is very open to feedback when work/life stresses 
                        mean additional recovery is necessary. Because Nick is still actively 
                        racing in the professional field, he's also very knowledgeable when 
                        it comes to the latest on nutrition, bikes, shoes, etc. and has been 
                        a role model for perseverance and dedication as he's come back from 
                        injury to top form.
                        </Typography>
                        <Typography>- Shylah Andrews</Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}