import React from 'react';
import { 
    Grid2
} from '@mui/material';
import Plans from './plans';

export default function TrainingPlans() {
    return (
        <Grid2 container spacing={2} justifyContent={"center"}>
            <Grid2 item>
                <Plans></Plans>
            </Grid2>
        </Grid2>
    )
}