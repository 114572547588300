import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { CardMedia } from '@mui/material'
import nickTriWin from './../../images/nick-win.png'
import nickCollegeWin from './../../images/runner.jpg'
import nickAndMatt from './../../images/nick-and-matt.jpg'

export default function ImageCarousel(props)
{
    return (
        <Carousel sx={{
            '& .carousel-content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
          navButtonsAlwaysVisible={true}
          animation='slide'
          autoPlay={false}
          >
            <div className='carousel-content'>
                <CardMedia
                    component="img"
                    src={nickTriWin}
                    alt={props.alt}
                    style={
                        { width: "40%", height: "40%", justifyContent:"cemter"}
                    }
                />
            </div>
            <div className='carousel-content'>
                <CardMedia
                    component="img"
                    src={nickCollegeWin}
                    alt={props.alt}
                    style={{ width: "40%", height: "40%" }}
                />
            </div>
            <div className='carousel-content'>
                <CardMedia
                    component="img"
                    src={nickAndMatt}
                    alt={props.alt}
                    style={{ width: "30%", height: "40%" }}
                />
            </div>
        </Carousel>
    )
}