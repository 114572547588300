import React, { useState } from 'react';
import { 
    BottomNavigation, 
    BottomNavigationAction, 
    Box
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import StravaIcon from './footer-components/strava-icon';
import Contact from './contact-components/contact';

export default function Footer(props) {
    const [contactOpen, setContactOpen] = useState(false)

    const handleOpen = () => setContactOpen(true)
    const handleClose = () => setContactOpen(false);

    return (
        <>
            <Box 
                sx={{ 
                    position: 'fixed', 
                    bottom: 0, 
                    left: 0, 
                    right: 0,  
                    zIndex: `${props.zIndex}`
                }} 
                elevation={3}
                width={"100%"}
                
            >
                <BottomNavigation
                    showLabels
                    sx={{bgcolor:'transparent'}}
                >
                    <BottomNavigationAction
                        onClick={() => {
                            window.location.href="https://www.instagram.com/nick.marcantonio/"
                        }}
                        label="Instagram"
                        icon={<InstagramIcon />} 
                        class={'filter-white'}
                    />
                    <BottomNavigationAction
                        onClick={() => {
                            window.location.href="https://www.strava.com/pros/42117994"
                        }}
                        label="Strava" 
                        icon={<StravaIcon />} 
                        class={'filter-white'}
                    />
                    <BottomNavigationAction
                        onClick={handleOpen}
                        label="Contact" 
                        icon={<AlternateEmailIcon />} 
                        class={'filter-white'}
                    />
                </BottomNavigation>
            </Box>
            <Contact open={contactOpen} handleClose={handleClose}/>
        </>
    )
}