import React from 'react';
import { AppBar, Toolbar, Box, CssBaseline } from '@mui/material';
import logo from './../images/logo-ae.svg';

export default function Header() {
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar style={{
                position:"absolute",
                background: 'transparent', 
                boxShadow: 'none',
                top:"0px",
                left:"0px",
            }}
            >
                <Toolbar> 
                    <div style={{
                        position:"absolute", 
                        background: 'transparent', 
                        boxShadow: 'none', 
                        height: "100%",
                        width: "100%",
                        borderRadius:5, 
                        display:"inline-block", 
                        top:0,
                        left:0,
                        zIndex: 2
                    }}>
                        <div style={{
                            backgroundColor:"'#126B21",
                            display:"flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Box 
                                component="img"
                                src={logo}
                                sx={{
                                    padding: {xs: "1%"},
                                    width: {xs: "30%",sm:"25%", md:"15%", lg:"15%"},
                                    height: "100%"
                            }}/>              
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}