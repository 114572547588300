import React, { useState } from 'react';
import { 
    TextField,
    Button,
    Modal,
    Grid2,
    Typography,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import emailjs from 'emailjs-com';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

const labelStyle = {
    style: {color:"black"}
};

emailjs.init({
    publicKey: 'fHDoGgs_w2YhmM0M5',
    blockHeadless: true,
    limitRate: {
    // Set the limit rate for the application
    id: 'app',
    // Allow 1 request per 10s
    throttle: 10000,
  },
});

export default function Contact(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState(props.subject ?? '')
    const [message, setMessage] = useState(props.message ?? '');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);

    const handleInputChange = (type, event) => {
        switch (type) {
            case "name":
                setName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            case "subject":
                setSubject(event.target.value);
                break;
            case "message":
                setMessage(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleContact = (event) => {
        event.preventDefault();
        props.handleClose();
        setDialogOpen(true);
        setSendingMessage(true);
        setDialogTitle('Sending Email');
        setMessage('');
        setName('');
        setSubject('');
        setEmail('');

        emailjs.sendForm(
            'service_12id7v5',
            'template_wsvt5x2',
            event.target,
            'fHDoGgs_w2YhmM0M5'
        ).then(
            (response) => {
                setSendingMessage(false);
                setDialogTitle('Success');
                setDialogMessage('Nick will reach out to you soon');
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                setSendingMessage(false);
                setDialogTitle('Failed');
                setDialogMessage(
                `
                    We are working on a fix, please bear with us or try again at 
                    a later time
                `);
                console.log('FAILED...', error);
            },
        );
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="contact-us-modal-title"
                aria-describedby="contact-us-modal-description"
            >
                <Box sx={style}>
                    <Typography 
                        id="modal-modal-title" 
                        variant="h6" 
                        component="h2"
                        style={{color:"black"}}
                    >
                        Contact Us
                    </Typography>
                    <form onSubmit={handleContact}>
                        <Grid2  
                            container
                            justifyContent="space-between"
                            alignItems={"right"}
                        >
                            <Grid2 item size={12}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    variant="filled"
                                    id="from_name"
                                    name="from_name"
                                    value={name}
                                    onChange={(event) => {handleInputChange("name", event)}}
                                    required
                                    InputLabelProps={labelStyle}
                                />
                            </Grid2>
                            <Grid2 item size={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    type="email"
                                    variant="filled"
                                    id="from_email"
                                    name="from_email"
                                    value={email}
                                    onChange={(event) => {handleInputChange("email", event)}}
                                    required
                                    InputLabelProps={labelStyle}
                                />
                            </Grid2>
                            <Grid2 item size={12}>
                                <TextField
                                    fullWidth
                                    label="Subject"
                                    variant="filled"
                                    id="subject"
                                    name="subject"
                                    value={subject}
                                    onChange={(event) => {handleInputChange("subject", event)}}
                                    required
                                    InputLabelProps={labelStyle}
                                />
                            </Grid2>
                            <Grid2 item size={12}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    id="message"
                                    name="message"
                                    variant="filled"
                                    multiline
                                    value={message}
                                    onChange={(event) => {handleInputChange("message", event)}}
                                    rows={6}
                                    required
                                    InputLabelProps={labelStyle}
                                />
                            </Grid2>
                            <Grid2 item size={12}>
                                <Stack direction="row" justifyContent="end" width={"100%"}>
                                    <Button type="submit">Send</Button>
                                    <Button onClick={() => {
                                        setMessage('');
                                        setName('');
                                        setSubject('');
                                        setEmail('');
                                        props.handleClose()
                                    }}>Cancel</Button>
                                </Stack>
                            </Grid2>
                        </Grid2>
                    </form>
                </Box>
            </Modal>
            <Dialog onClose={handleDialogClose} open={dialogOpen}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                { !sendingMessage && 
                    <>
                        <Divider/>
                        <DialogContent color="black">{dialogMessage}</DialogContent>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>Close</Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
        </>
    );
}