import React from 'react';
import { 
    Collapse, 
    ListItemButton, 
    List,
    ListItemText,
    Divider
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TrainingPlans from './body-components/training-plans';
import Testimonials from './body-components/testimonials';
import About from './body-components/about';

export default function Body(props) {
    const [plansOpen, setPlansOpen] = React.useState(false);
    const [aboutOpen, setAboutOpen] = React.useState(window.innerHeight > 800);
    const [testimonialOpen, setTestimonialOpen] = React.useState(false);

    const handleClickCollapse = (section) => {
        switch(section) {
            case "plans":
                setPlansOpen(!plansOpen);
                setAboutOpen(false);
                setTestimonialOpen(false);
                break;
            case "about":
                setAboutOpen(!aboutOpen);
                setPlansOpen(false);
                setTestimonialOpen(false);
                break;
            case "testimonial":
                setTestimonialOpen(!testimonialOpen);
                setPlansOpen(false);
                setAboutOpen(false);
                break;
            default:
                break;
        }
    };

    return (
        <div style={{
            marginLeft:"10%",
            marginRight:"10%",
            marginTop:`10%`,
            marginBottom:"10%"
        }}>
            <List 
                sx={{ width: '100%' }}
                component="nav"
                aria-labelledby="nested-list-subheader"    
            >
                <ListItemButton divider onClick={() => handleClickCollapse("about")}>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '2em'}}
                        primary="Your Coach" 
                    />
                    {aboutOpen ? <ExpandLess style={{color:'#484b4e'}}/> : <ExpandMore style={{color:'#484b4e'}}/>}
                </ListItemButton>
                <Divider/>
                <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
                    <About/>
                </Collapse>
                <ListItemButton divider onClick={() => handleClickCollapse("plans")}>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '2em'}}
                        primary="Training Plans" 
                    />
                    {plansOpen ? <ExpandLess style={{color:'#484b4e'}}/> : <ExpandMore style={{color:'#484b4e'}}/>}
                </ListItemButton>
                <Divider/>
                <Collapse in={plansOpen} timeout="auto" unmountOnExit>
                    <TrainingPlans/>
                </Collapse>
                <ListItemButton divider onClick={() => handleClickCollapse("testimonial")}>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '2em'}}
                        primary="Success Stories" 
                    />
                    {testimonialOpen ? <ExpandLess style={{color:'#484b4e'}}/> : <ExpandMore style={{color:'#484b4e'}}/>}
                </ListItemButton>
                <Divider/>
                <Collapse in={testimonialOpen} timeout="auto" unmountOnExit>
                    <Testimonials/>
                </Collapse>
            </List>
        </div>
    );
};
