import React, { useRef, useEffect, useState } from 'react';
import Header from './components/header';
import './App.css';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import Body from './components/body';
import headerImage from './images/lg-landscape.svg'
import conservationSoftwareLogo from './images/logo-cs.svg'
import Footer from './components/footer';
import { Box } from '@mui/system';
import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';

function App() {
  const landscapeRef = useRef(null);
  const [lanscapeHeight, setLanscapeHeight] = useState(0);
  const [csOpen, setCsOpen] = useState(false);

  const handleDialogClose = () => {
    setCsOpen(false);
  };

  useEffect(() => {
    if (landscapeRef.current) {
      setLanscapeHeight(
        (landscapeRef.current.getBoundingClientRect().height - 50)
      );
    }
  }, []);

  let theme = createTheme({
    palette: {
      background: {
        default: "#0e1111"
      }
    },
    typography: {
      body1: {
        color: 'white',
      },
      h1: {
        color: 'white',
      },
      h2: {
        color: 'white',
      },
      h3: {
        color: 'white',
      },
      fontFamily: ['Quantico', 'cursive'].join(',')
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          }
        }
      },
    }
  });

  theme = responsiveFontSizes(theme)

  return (
    <ThemeProvider theme={theme}>
        <Header style={{zIndex:70, position:"relative"}}/>
        <Box
          ref={landscapeRef}
          component="img"
          src={headerImage} 
          alt="Header"
          style={{
              top:"0px",
              left:"0px",
              position:"absolute",
              height:{md:"10%",lg:"5%"}, 
              zIndex:5
          }}
        />
        <Body headerHeight={lanscapeHeight}
          style={{
            zIndex:300,
            position:"absolute"
          }}
        
        />
        <Footer zIndex={1000}/>
        
        <Box 
            component="img"
            onClick={() => {setCsOpen(true)}}
            src={conservationSoftwareLogo}
            sx={{
                bottom:"0px",
                right:"0px",
                position:"fixed", 
                zIndex:0,
                padding: {xs: "1%"},
                width: {xs: "30%",sm:"20%", md:"15%", lg:"15%"},
                height: {xs: "15%",sm:"10%", md:"10%", lg:"13%", xl:"16%"},
        }}/>
        <Dialog open={csOpen} onClose={handleDialogClose}>
          <DialogTitle>Built by Conservation Software</DialogTitle>
          <Divider/>
          <DialogContent>
            Inquire more by emailing <a 
              href='/'
              onClick={() => {
                navigator.clipboard.writeText('chase.gregory.cs@gmail.com')}
              }>
                chase.gregory.cs@gmail.com
              </a> free quotes available
          </DialogContent>
        </Dialog>
    </ThemeProvider>
  );
}

export default App;
