import React from 'react';
import { 
    Typography, 
    Grid2
} from '@mui/material';
import ImageCarousel from './carousel-slide';

export default function About() {
    return (
        <Grid2 container spacing={2} width={"100%"}
                sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid2 item size={12}>
                <ImageCarousel/>
            </Grid2>
            <Grid2>
                <Typography variant="subtitle1" fontStyle="italic">
                    Nick coaches triathletes and runners of all levels. He developed his love for endurance sports in high school as a 
                    runner. This led to him pursuing a college degree in exercise science from SUNY Cortland, where he also ran cross 
                    country and track. He began racing as a professional triathlete and coaching athletes in 2021 following age group 
                    wins at Ironman 70.3 Puerto Rico, 70.3 Lake Placid, and 70.3 Connecticut. As a coach, Nick is determined to develop 
                    training for his athletes that will allow them to reach their goals and potential in a way that fits their lifestyle. 
                </Typography>
            </Grid2>
            <Grid2>
                <Typography variant="subtitle1" fontStyle="italic">
                    Nick's approach is meticulous and focused on the individual needs of each athlete. He takes the time to understand 
                    their unique goals, strengths, and areas for improvement, and tailors his coaching to meet those needs. With a keen 
                    eye for detail and a wealth of experience, Nick empowers his athletes to unlock their potential and achieve exceptional 
                    results.
                </Typography>
            </Grid2>
            <Grid2>
                <Typography variant="subtitle1" fontStyle="italic">
                    Choosing Nick as your triathlon coach means not only gaining access to his expertise but also to his unwavering support 
                    and dedication. He is a true partner in your journey to success, providing personalized guidance, practical strategies, 
                    and the motivation needed to help you reach your triathlon or running goals.
                </Typography>
            </Grid2>
            <Grid2>
                <Typography variant="subtitle1" fontStyle="italic">
                    Invest in your performance today by choosing Nick Marcantonio as your triathlon coach. His proven track record and 
                    personalized approach make him an ideal partner in your pursuit of athletic excellence.
                </Typography>
            </Grid2>
        </Grid2>
    );
}