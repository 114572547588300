import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, alpha } from '@mui/material/styles';
import Contact from '../contact-components/contact';

const AeButton = styled(Button)({
    boxShadow: 'none',
    height: "100%",
    width: "100%",
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: "transparent",
    borderColor: '#126B21',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: alpha("#126B21", 0.1),
      borderColor: '#126B21',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: "#126B21",
      borderColor: '#126B21',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

export default function PlanBox(props) {
  const [open, setOpen] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenContact = () => {
    setOpen(false);
    setOpenContact(true)
  }

  const handleCloseContact = () => {
    setOpenContact(false)
  }

  return (
    <>
      <React.Fragment>
        <AeButton variant="outlined" onClick={handleClickOpen}>
          {props.children}
        </AeButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.header}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenContact}>Contact</Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Contact 
        open={openContact} 
        handleClose={handleCloseContact}
        subject={`${props.header} Inquiry`}
        message={props.defaultMessage}
      />
    </>
  );
}